// Font styling
$font-family-base: 'Poppins', sans-serif;
$font-size-base: 16px;

// Color styling
$color-theme-1: #0017bb;
$color-theme-2: #db4373;
$color-background-light: #ffffff;
$color-background-silver-70: rgba(242, 243, 251, 0.7);
$color-background-grayed: #ededf8;
$color-background-grayed-light-45: rgba(234, 237, 243, 0.45);
$color-text-light: #ffffff;
$color-background-blue-light: #f5f6fc;
$color-text-dark: #333333;
$color-text-grayed-dark: #484848;
$color-text-grayed: #767676;
$color-text-grayed-light: #72808c;
$color-pagination: #637280;
$color-pagination-light: #ffffff;
$color-text-main: #637280;
$color-text-main-50: rgba(99, 114, 128, 0.5);
$color-error: #ff5252;
$color-background-error: #ffeded;

// Border styling
$border-radius: 4px;

// Grid sizes
$sm-size: 48em;
$md-size: 62em;
$lg-size: 75em;

// Margin
$size-to-menu-desktop: 130px;
$size-to-menu-mobile: 54px;
$size-to-footer-mobile: 54px;
