@import '../../styles/_inocrowd-variables';
@import '../../styles/_mixins';

#mobile-home-slider {
  .card {
    white-space: normal;
    overflow: hidden;
    width: 273px;
    background: $color-background-light;
    margin: 0 5px;
    display: inline-block;
    vertical-align: top;
    @include border-radius(4px);
    @include box-shadow(0 2px 10px 0 rgba(16, 29, 191, 0.2));

    &.first {
      margin-left: 0;
    }

    &.last {
      margin-right: 0;
    }

    .image {
      width: 100%;
      height: 151px;
      @include background-size(cover);
      background-position: center center;
      background-repeat: no-repeat;
    }

    .content {
      padding: 10px 15px;

      .card-title {
        font-size: 1.125rem;
        line-height: 1.389;
        font-weight: 600;
        letter-spacing: 0.12px;
        color: $color-text-dark;
        margin-bottom: 10px;
        @include line-clamp(2);
        height: calc(1.125rem * 1.389 * 2);
      }

      .card-description {
        font-size: 1rem;
        line-height: 1.563;
        font-weight: 400;
        letter-spacing: 0.11px;
        color: $color-text-main;
        opacity: 0.6;
        margin-bottom: 15px;
        @include line-clamp(3);
        height: calc(1rem * 1.563 * 3);
      }

      .card-row {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        white-space: nowrap;
        @include flexbox();

        .card-price {
          display: inline-block;
          @include border-radius(21px);
          border: 1px solid $color-theme-2;
          color: $color-theme-2;
          padding: 10px 15px;
          font-size: 1rem;
          line-height: 1.063;
          letter-spacing: 0.1px;
          text-align: center;
          vertical-align: top;
        }

        .card-date {
          display: inline-block;
          @include border-radius(21px);
          border: 1px solid $color-text-main-50;
          color: $color-text-main;
          padding: 10px 15px;
          font-size: 1rem;
          line-height: 1.063;
          letter-spacing: 0.1px;
          text-align: center;
          white-space: nowrap;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow: hidden;
          vertical-align: top;
          margin-left: 5px;

          img {
            display: inline-block;
            margin-right: 5px;
            height: 17.6px;
            width: auto;
            vertical-align: top;
          }
        }
      }

      .card-link {
        display: block;
        @include border-radius(4px);
        border: 1.5px solid $color-theme-2;
        padding: 10px 20px;
        font-size: 1rem;
        line-height: 1.563;
        font-weight: 500;
        color: $color-theme-2;
        @include transition(all 0.5s ease-in-out);
        background: transparent;
        text-align: center;
        text-decoration: none;

        &:hover {
          background: $color-theme-2;
          color: $color-text-light;
        }
      }
    }
  }
  .slider {
    height: 540px;
    .card{
      width: 100%;
    }
  }
}
