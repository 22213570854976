@import '../../styles/_inocrowd-variables';

.inc-mobile-slider{
  .slider {
    width: 100%;
    position: relative;
    height: 60vw;
    display: block;
    overflow: hidden;

    .slides {
      width: 100%;
      height: 167%;
      position: absolute;
      left: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.animated {
        transition: transform 0.3s ease;
      }

      .slide {
        position: absolute;
        top: 0;
        transform: scale(0.9);
        transition: padding 0.3s ease 0.6s, transform 0.3s ease, opacity 0.3s ease 0.6s;
        flex-shrink: 0;
        width: 60%;
        height: 60%;

        &.selected {
          transform: scale(1);
        }
      }
    }
  }

  .pagination {
    width: 100%;
    display: block;
    margin-top: 30px;
    margin-bottom: 60px;

    .circle {
      content: ' ';
      display: inline-block;
      width: 11px;
      height: 11px;
      background-color: $color-pagination-light;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color .3s;
      margin: 0 5px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent; /* For some Androids */

      &.selected {
        background-color: $color-pagination;
      }
    }
  }
}

