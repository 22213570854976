@import "../../styles/_inocrowd-variables.scss";

.not-implemented-wrapper {
  margin-top: 50px;

  h1 {
    color: $color-text-grayed;
    font-size: 1.75rem;
    font-weight: 400;
    padding-top: 70px;
    padding-left: 58px;
  }

  img {
    width: 100%;
    height: auto;
  }

  div.center {
    padding-top: 30px;
    padding-bottom: 100px;
    text-align: center;

    span {
      color: $color-text-grayed;
      font-size: 1.75rem;
      font-weight: 400;
    }
  }
}
