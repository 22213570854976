.testimonials{

  &.is-video-open{
    z-index: 200000 !important;
  }

  .slider-wrapper-outer .controls .prev,
  .slider-wrapper-outer .controls .next {
    top: 110px !important;
  }

  .video-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
    z-index: 5;
    background-color: rgba(0,0,0,0.8);

    &.is-video-open{

      opacity: 1;
      pointer-events: all;
    }

    .video-container{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 85%;
      max-width: 1920px;
      transform: translate(-50%, -50%);
      padding-top: 48%;

      .video{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }

      .close{
        position: absolute;
        bottom: calc(100% + 15px);
        right: 15px;
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }

  }
}

@media (orientation:landscape){
  .testimonials{

    .video-wrapper .video-container .close{
      bottom: auto;
      top: 5px;
      right: auto;
      left: calc(100% + 10px);
      width: 20px;
      height: 20px;
    }
  }
}
