@import '../../styles/_inocrowd-variables';
@import '../../styles/_mixins';

.video-card {
  position: relative;
  width: 260px !important;
  height: 350px !important;
  box-shadow: none !important;
  overflow: visible !important;
  cursor: pointer;

  &.open-video {
    cursor: default;
  }

  .poster {
    width: 100%;
    height: 260px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 7px 16px 0 rgba(16, 29, 191, 0.1);
    border-radius: 8px;
    transition: filter 300ms;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 260px;
    background-color: rgba(189, 194, 230, 0.4);
    opacity: 0;
    transition: all 300ms;
    pointer-events: none;
    border-radius: 8px;
  }

  .play {
    position: absolute;
    top: 65px;
    left: 50%;
    height: 130px;
    width: 130px;
    transform: translateX(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    opacity: 0;
    transition: all 300ms;
  }

  &.hover {
    .poster {
      filter: grayscale(100%);
    }

    .overlay {
      opacity: 1;
    }

    .play {
      opacity: 1;
    }
  }

  .card-title {
    margin-top: 14px;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #333;
    letter-spacing: normal;
  }

  .card-subtitle {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #637280;
    letter-spacing: normal;
  }
}

