@import '../../styles/_inocrowd-variables';
@import '../../styles/_mixins';

.no-story-image {
  font-family: Poppins;
  font-size: 43px;
  color: #637280;
}
.flip-on-hover {
  width: 100%;
  perspective: 1600px;

  .story-card {
    transform: rotateY(-180deg);
  }
}

.story-card {
  position: relative;
  z-index: 2;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
  margin-top: 14px;
  margin-bottom: 14px;

  .face {
    width: 100%;
    height: 100%;
    padding: 36px 25px 25px;
    border-radius: $border-radius;
    background-color: $color-background-light;
    box-shadow: 0 2px 10px 0 rgba(16, 29, 191, 0.1);
    backface-visibility: hidden;

    &.front {
      display: none;
    }

    &.back {
      z-index: -1;
      transform: rotateY(-180deg);
      transition: opacity 0.5s linear 0s;
      font-family: $font-family-base;
      color: $color-text-grayed;
      text-align: center;

      .image-content {
        text-align: center;
        width: 100%;

        img {
          max-height: 65px;
          max-width: 50%;
        }
      }

      .testimony {
        display: block;
        margin-top: 15px;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.17px;
        line-height: 27px;

        &.with-quotes {
          &:before {
            content: '\201C';
          }
          &:after {
            content: '\201D';
          }
        }
      }

      .attestant {
        margin-top: 27px;
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.16px;
        line-height: 22px;
      }

      .attestantJobRole {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.16px;
        line-height: 22px;
      }

      .btn {
        width: 100%;
        margin-top: 25px;
        -webkit-box-orient: vertical;
      }

      .btn-success-stories-card {
        padding: 8px 18px;
        color: $color-theme-2;
        border: 2px solid $color-theme-2;
        @include border-radius(4px);
        @include transition(all 500ms ease-in-out);
        background: transparent;

        &:hover {
          color: $color-text-light;
          background: $color-theme-2;
        }
      }
    }
  }
}

@media only screen and (min-width: $sm-size) {
  .flip-on-hover:not(.flipped) {
    .story-card {
      transform: rotateY(0deg);
    }

    &:hover {
      .story-card {
        transform: rotateY(-180deg);
      }
    }
  }

  .story-card {
    height: 480px;

    .face {
      position: absolute;
      padding: 40px 25px 32px;

      &.front {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        transition: opacity 0s ease-in-out;

        .image-content {
          text-align: center;
          width: 100%;

          img {
            width: 70%;
            height: auto;
          }
        }
      }

      &.back {
        .testimony {
          font-size: 16px;
          line-height: 28px;
          height: 168px;
          letter-spacing: 0.18px;

          @include line-clamp(6);
        }

        .attestant {
          margin-top: 37px;
          height: 22px;
        }

        .attestantJobRole {
          height: 22px;
        }

        .btn {
          margin-top: 27px;
        }
      }
    }
  }
}
