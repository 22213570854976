@import '../../styles/_inocrowd-variables';
@import '../../styles/_mixins';

.home-page {
  .page-header {
    padding: 40px 0;

    h1 {
      font-size: 1.5rem;
      color: $color-text-dark;
      letter-spacing: 0.17px;
      line-height: 1.333;
      font-weight: 400;
      margin-bottom: 16px;
      text-align: left;
    }

    .description {
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: normal;
      line-height: 1.5;
      color: $color-text-main;
      margin-bottom: 28px;
      text-align: left;
    }

    .buttons a {
      display: inline-block;
      min-width: 120px;
      &:nth-child(1) {
        margin-right: 10px;
      }
    }

    .media {
      .has-media {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 3;
        @include transform(translate(-50%, -50%));
        height: 100px;
        width: 100px;
        background: url('../../assets/icons/ic-play-blue.svg') no-repeat center center;
				@include background-size(contain);
				transition: opacity 0.6s ease-in-out;
				&:hover{
          cursor: pointer;
					opacity: 0.8;
				}
      }
    }

    .title {
      z-index: 2;
    }

    .image {
			position: relative;

      .img {
        display: block;
				opacity: 0;
				margin-left: 10px;
				height: 300px;
        width: 325px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        @include transform(translate(-50%, -50%));
        width: 146%;
        height: 271%;
        background: url('../../assets/illustrations/img_video.png') no-repeat center center;
        @include background-size(contain);
      }
    }

    a {
      display: inline-block;
      width: 174px;
      padding: 15px 20px;
      float: left;
      @include border-radius(4px);
      background-color: $color-theme-1;
      font-size: 0.938rem;
      line-height: 1.5333;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-align: center;
      color: $color-text-light;
      text-decoration: none;
    }

    .btn-hover-effect {
      padding: 13px 18px;
      color: $color-background-light;
      border: 2px solid $color-theme-1;
      @include border-radius(4px);
      @include transition(all 500ms ease-in-out);
      background: $color-theme-1;
      &:hover {
        color: $color-theme-1;
        background: transparent;
      }
    }

    .btn-hover-effect-2 {
      padding: 13px 18px;
      color: $color-background-light;
      border: 2px solid $color-theme-2;
      @include border-radius(4px);
      @include transition(all 500ms ease-in-out);
      background: $color-theme-2;
      &:hover {
        color: $color-theme-2;
        background: transparent;
      }
    }
  }

  .main-success-stories {
    position: relative;

    .shape {
      position: absolute;
      top: -30px;
      right: 20%;
      z-index: 0;

      .parallax-inner {
        width: 754px;
        height: 578px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    h2.title {
      font-size: 1.5rem;
      line-height: 1.458;
      margin-top: 20px;
      z-index: 1;
    }

    .stories-container {
      margin-top: 44px;
    }

    .inc-mobile-slider {
      margin-top: 44px;

      .slider {
        height: 514px;

        .story-card {
          .face.back {
            .testimony {
              line-height: 26px;
              height: 208px;

              @include line-clamp(8);
            }

            .attestant,
            .attestantJobRole {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 22px;
            }
          }
        }
      }
    }
  }

  .activity-areas {
    margin-top: 80px;

    h2.title {
      font-size: 1.5rem;
      line-height: 1.458;
      margin-top: 20px;
      z-index: 1;
    }

    .activities {
      margin-top: 5px;

      .activity {
        display: flex;
        width: 280px;
        margin-top: 45px;
        @include flex-direction(column);

        .img-container {
          width: 100%;
          height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }

        h5 {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0.11px;
          line-height: 25px;
          text-align: center;
          margin-top: 17px;
        }
      }
    }
  }

  .seeker-solver {
    padding: 40px 0;

    .content {
      text-align: left;

      .title {
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 1.533;
        letter-spacing: normal;
        color: $color-text-dark;
        margin-bottom: 19px;
      }

      .description {
        font-size: 1.063rem;
        line-height: 1.647;
        font-weight: 300;
        letter-spacing: normal;
        color: $color-text-main;
        margin-bottom: 22px;
      }

      a {
        width: 130px;
      }

    }

    .media {
      margin-bottom: 60px;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        background-repeat: no-repeat;
        background-position: center center;
        @include background-size(contain);
        z-index: 1;
      }

      &.theme-1 {
        margin-top: 40px;
        max-width: 500px;

        &:before {
          background-image: url('../../assets/illustrations/shape4.svg');
          width: 137%;
          height: 129%;
          right: 47%;
          top: 50%;
          @include transform(translateY(-50%));
        }

        &:after {
          background-image: url('../../assets/illustrations/shape2.svg');
          width: 42%;
          height: 53%;
          left: 70%;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }

      &.theme-2 {
        margin-top: 137px;
        max-width: 420px;

        &:before {
          background-image: url('../../assets/illustrations/shape3.svg');
          width: 167%;
          height: 118%;
          left: 54%;
          top: 36%;
          @include transform(translateY(-50%));
        }

        &:after {
          background-image: url('../../assets/illustrations/shape1.svg');
          width: 76%;
          height: 57%;
          left: 37%;
          top: 48%;
          @include transform(translateY(-50%));
        }
      }

      img {
        position: relative;
        z-index: 2;
        width: 100%;
      }
    }
  }

  .active-challenges {
    padding: 30px 0 10px;
    position: relative;
    z-index: 2;

    .shape {
      position: absolute;
      top: 0;
      left: -500px;
      width: 754px;
      height: 578px;
      z-index: -1;
    }

    .title {
      font-size: 1.5rem;
      line-height: 1.458;
      letter-spacing: normal;
      font-weight: 400;
      color: $color-text-dark;
      margin-bottom: 24px;
    }

    .slider-wrapper-outer {
      position: relative;
      overflow: hidden;

      .controls {
        display: none;
      }

      .slider-wrapper-inner {
        padding-bottom: 20px;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: -20px;
        overflow-y: hidden;
        overflow-x: auto;
        display: inline-block;
        width: 100vw;

        .slider {
          white-space: nowrap;
          display: inline-block;
          padding-top: 20px;
          padding-bottom: 20px;

          .card {
            white-space: normal;
            overflow: hidden;
            width: 273px;
            background: $color-background-light;
            margin: 0 5px;
            display: inline-block;
            vertical-align: top;
            @include border-radius(4px);
            @include box-shadow(0 2px 10px 0 rgba(16, 29, 191, 0.2));

            &.first {
              margin-left: 0;
            }

            &.last {
              margin-right: 0;
            }

            .image {
              width: 100%;
              height: 151px;
              @include background-size(cover);
              background-position: center center;
              background-repeat: no-repeat;
            }

            .content {
              padding: 10px 15px;

              .card-title {
                font-size: 1.125rem;
                line-height: 1.389;
                font-weight: 600;
                letter-spacing: 0.12px;
                color: $color-text-dark;
                margin-bottom: 10px;
                @include line-clamp(2);
                height: calc(1.125rem * 1.389 * 2);
              }

              .card-description {
                font-size: 1rem;
                line-height: 1.563;
                font-weight: 400;
                letter-spacing: 0.11px;
                color: $color-text-main;
                opacity: 0.6;
                margin-bottom: 15px;
                @include line-clamp(3);
                height: calc(1rem * 1.563 * 3);
              }

              .card-row {
                display: block;
                width: 100%;
                margin-bottom: 20px;
                white-space: nowrap;
                @include flexbox();

                .card-price {
                  display: inline-block;
                  @include border-radius(21px);
                  border: 1px solid $color-theme-2;
                  color: $color-theme-2;
                  padding: 10px 15px;
                  font-size: 1rem;
                  line-height: 1.063;
                  letter-spacing: 0.1px;
                  text-align: center;
                  vertical-align: top;
                }

                .card-date {
                  display: inline-block;
                  @include border-radius(21px);
                  border: 1px solid $color-text-main-50;
                  color: $color-text-main;
                  padding: 10px 15px;
                  font-size: 1rem;
                  line-height: 1.063;
                  letter-spacing: 0.1px;
                  text-align: center;
                  white-space: nowrap;
                  -ms-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  max-width: 100%;
                  overflow: hidden;
                  vertical-align: top;
                  margin-left: 5px;

                  img {
                    display: inline-block;
                    margin-right: 5px;
                    height: 17.6px;
                    width: auto;
                    vertical-align: top;
                  }
                }
              }

              .card-link {
                display: block;
                @include border-radius(4px);
                border: 1.5px solid $color-theme-2;
                padding: 10px 20px;
                font-size: 1rem;
                line-height: 1.563;
                font-weight: 500;
                color: $color-theme-2;
                @include transition(all 0.5s ease-in-out);
                background: transparent;
                text-align: center;
                text-decoration: none;

                &:hover {
                  background: $color-theme-2;
                  color: $color-text-light;
                }
              }
            }
          }
        }
      }
    }
  }

  .stats {
    padding: 30px 0 50px;
    position: relative;

    .shape {
      position: absolute;
      top: 33%;
      left: 40%;
      @include transform(translateY(-50%));
      width: 725px;
      height: 543px;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 400;
      color: $color-text-dark;
      line-height: 1.458;
      letter-spacing: normal;
      margin-bottom: 60px;
    }

    .item {
      &:not(:first-child) {
        margin-top: 50px;
      }

      .value {
        display: block;

        .icon {
          display: inline-block;
          width: 45px;
          height: 45px;
          @include background-size(contain);
          background-position: center center;
          background-repeat: no-repeat;
          vertical-align: top;
          margin-right: 12px;
        }

        span {
          font-size: 1.875rem;
          font-weight: 600;
          letter-spacing: 0.4px;
          line-height: 1.533;
          display: inline-block;
        }
      }

      &.theme-1 .value span {
        color: $color-theme-1;
      }

      &.theme-2 .value span {
        color: $color-theme-2;
      }

      .description {
        display: block;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1.533;
        letter-spacing: 0.2px;
        color: $color-text-main;
        margin-top: 12px;
        max-width: 180px;
      }
    }
  }

  .modal {
    position: fixed;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include flexbox();
    @include flex(0 1 auto);
    @include justify-content(center);
    @include align-items(center);
    padding: 10px;

    .video-wrapper {
      padding-bottom: 56.25%;
      width: 100%;
      max-width: 1200px;
      position: relative;

      video {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        @include transform(translateY(-50%));
      }
    }
  }

  .solvers-count-up {
    min-width: 200px;
  }
  .success-rate {
    min-width: 100px;
  }
  .days {
    min-width: 200px;
  }

  .partners{
    padding: 55px 0 135px;
    .title {
      font-size: 1.5rem;
      font-weight: 400;
      color: $color-text-dark;
      line-height: 1.458;
      letter-spacing: normal;
      margin-bottom: 50px;
    }
    .partner{
      img{
        transform: scale(1);
        transition: all .4s ease-in-out;
        margin-bottom: 20px;
        &:hover{
          transform: scale(1.03);
        }
        height: 49px;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: $sm-size) {
  .home-page {
    .main-success-stories {
      .shape {
        display: none;
      }
    }
  }
}

@media (min-width: $md-size) {
  .home-page {
    .page-header {
      padding: 160px 0;

			.image {
				.img {
					width: 525px;
				}
			}
      h1 {
        font-size: 2.375rem;
        max-width: 380px;
        margin-bottom: 23px;
      }

      .description {
        max-width: 442px;
        line-height: 1.556;
        font-size: 1.125rem;
        margin-bottom: 50px;
      }

      .buttons {
        display: inline-flex;
        justify-content: center;
      }
      .buttons a {
        justify-content: center;
        min-width: 170px;
        max-width: 170px;

        &:nth-child(1) {
          margin-right: 15px;
        }
      }
      .side-media {
        margin-top: 0;
        position: absolute;
        top: -315px;
        right: -165px;
        max-width: 820px;
        width: 65%;
        &:after {
          top: 135%;
          left: -16%;
          height: 827.64px;
          width: 52em;
        }
        img {
          max-width: none;
          width: 525px;
          margin-right: 0;
        }
      }
    }

    .main-success-stories {

      h2.title {
        font-size: 1.875rem;
        line-height: 1.533;
        margin-top: 50px;
      }

      .stories-container {
        margin-top: 64px;
      }
    }

    .activity-areas{
      margin-top: 125px;

      h2.title {
        font-size: 1.875rem;
        line-height: 1.533;
      }

      .activities {
        .activity {
          width: 280px;
          margin-top: 65px;

          h5 {
            margin-top: 23px;
          }
        }
      }
    }

    .seeker-solver {
      .content {
        &.theme-1 {
          max-width: 535px;
        }

        &.theme-2 {
          max-width: 560px;
        }

        .know-more {
          display: inline-block;
          padding: 15px 40px;
        }

        .description {
          margin-bottom: 32px;
        }
      }

      .media {
        &.theme-1 {
          margin-left: -79px;
        }
        &.theme-2 {
          margin-top: 78px;
        }
      }
    }

    .active-challenges {
      padding: 55px 0;

      .shape {
        left: -74px;
      }

      .title {
        font-size: 1.875rem;
        line-height: 1.533;
      }

      .slider-wrapper-outer {
        .controls {
          display: block;
          position: relative;
          z-index: 2;

          .prev,
          .next {
            position: absolute;
            top: 222px;
            background: url('../../assets/icons/ic-angle-wbg.svg');
            width: 80px;
            height: 80px;
            background-repeat: no-repeat;
            background-position: center center;
            @include background-size(contain);
            border: 0;
            padding: 0;
            margin: 0;
            cursor: pointer;

            &.hide {
              display: none;
            }
          }

          .prev {
            left: 0;
            @include transform(rotate(180deg));
          }
          .next {
            right: 0;
          }
        }

        .slider-wrapper-inner {
          .slider {
            .card {
              width: 363px;
              height: 480px;
              margin: 0 17.5px;
              @include box-shadow(0 7px 16px 0 rgba(16, 29, 191, 0.1));

              .content {
                padding: 20px 27px;

                .card-title {
                  margin-bottom: 15px;
                }

                .card-description {
                  margin-bottom: 23px;
                }

                .card-row {
                  margin-bottom: 25px;

                  .card-price {
                    @include border-radius(50px);
                    padding: 11px 23px;
                  }

                  .card-date {
                    @include border-radius(50px);
                    padding: 11px 23px;
                    margin-left: 7.5px;

                    img {
                      margin-right: 7px;
                    }
                  }
                }

                .card-link {
                  padding: 11px 20px;
                }
              }
            }
          }
        }
      }
    }

    .stats {
      padding: 55px 0 75px;

      .shape {
        top: auto;
        bottom: 13px;
        @include transform(translateY(0));
        right: -250px;
        left: auto;
      }

      .title {
        font-size: 1.875rem;
        line-height: 1.533;
        margin-bottom: 75px;
      }

      .item {
        &:not(:first-child) {
          margin-top: 0;
        }

        .value {
          .icon {
            margin-right: 17px;
            vertical-align: -8px;
          }

          span {
            font-size: 2.5rem;
            letter-spacing: 0.53px;
            line-height: 1.5;
          }
        }

        .description {
          max-width: none;
          margin-top: 5px;
        }
      }
    }

    .partners{
      h2.title {
        font-size: 1.875rem;
        line-height: 1.533;
      }
      .partner{
        img{
          margin-bottom: 0;
        }
        &:not(:first-child){
          margin-left: 60px;
        }
      }
    }
	}
}

@media (min-width: 1224px) {
  .active-challenges .slider-wrapper-outer .slider-wrapper-inner .slider {
    padding-left: calc(51% - 597px);
  }
  .active-challenges .slider-wrapper-outer .slider-wrapper-inner .two-cards {
    padding-left: calc(51% - 597px + 181px);

  }
}

@media (max-width: $md-size) {
  .home-page {
    .page-header {
      .image {
        margin-top: 200px;
        margin-bottom: 150px;
        img {
          width: 70%;
          margin: auto;
        }
      }
      &:before {

      }
    }
  }
}

@media (max-width: 1025px) and (min-width: 500px) {
  .home-page {
    .know-more {
      width: 170px;
    }
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .home-page {
    .page-header {
      .buttons {
        width: 100%;
        text-align: center;
      }
      .buttons a {
        width: 48%;
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 10px;
      }
    }
    .seeker-solver {
      .content {
        a {
          width: 100%;
        }
      }
    }
  }
}

#mobile-home-slider {

}
