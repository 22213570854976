@import '../../styles/_inocrowd-variables';
@import '../../styles/_mixins';

.btn {
  cursor: pointer;
  display: block;
  padding: 12px 22px;
  text-align: center;
  border-radius: $border-radius;
  border: 2px solid $color-theme-2;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-decoration: none;

  &:disabled {
    cursor: default;
  }

  &.btn-small {
    padding: 8px 18px;
  }

  &.btn-primary {
    background-color: $color-background-light;
    color: $color-theme-2;

    &:not(:disabled) {
      &:hover {
        color: $color-background-light;
        background-color: $color-theme-2;
      }
    }
  }

  &.btn-secondary {
    background-color: $color-theme-2;
    color: $color-background-light;

    &:not(:disabled) {
      &:hover {
        background-color: $color-background-light;
        color: $color-theme-2;
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.tertiary-button{
  color: #fff;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 14px;
  border-radius: 4px;
  white-space: nowrap;
  padding: 13px 20px;
  opacity: 1;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  display: block;
  text-decoration: none;
  text-align: center;

  &.small {
    font-size: 0.8125rem;
    padding: 8px 20px;
  }

  &.big {
    font-size: 1.07rem;
    padding: 17px 20px;
  }

  &.small {
    font-size: 0.8125rem;
    padding: 9px 28px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  border: 1px solid #db4373;
  background-color: #db4373;
  box-shadow: 0 3px 6px 0 rgba(255, 100, 198, 0.2);

  &:active {
    background-color: #C43C66;
    box-shadow: 0 7px 16px 0 rgba(255, 100, 198, 0.2);
  }

  &:disabled {
    background-color: #db4373;
  }
}

.btn-color-theme-1 {
  color: $color-text-light;
  min-width: 170px;
  //max-width: 170px;
  text-align: center;
  border: 2px solid $color-theme-1;
  padding: 13px 7px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 1.5;
  @include border-radius(4px);
  @include transition(all 500ms ease-in-out);
  background: $color-theme-1;
  &:not(:disabled) {
    &:hover {
      color: $color-theme-1;
      background: transparent;
    }
  }
}

.btn-color-theme-2 {
  color: $color-text-light;
  min-width: 170px;
  //max-width: 170px;
  border: 2px solid $color-theme-2;
  padding: 13px 7px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.19px;
  line-height: 1.5;
  text-align: center;
  @include border-radius(4px);
  @include transition(all 500ms ease-in-out);
  background: $color-theme-2;
  &:not(:disabled) {
    &:hover {
      color: $color-theme-2;
      background: transparent;
    }
  }
}

.round-button {
  width: fit-content;
  color: $color-text-main;
  border: 1px solid rgba($color-text-main, 0.5);
  border-radius: 21px;
  text-align: center;
  min-height: 42px;
  line-height: 2.625rem;
  vertical-align: middle;
  padding: 0 14px 0 14px;
  white-space: nowrap;

  i {
    line-height: 2.625rem;
    vertical-align: middle;
    padding-right: 5px;
  }

  &.primary {
    color: $color-theme-2;
    border: 1px solid $color-theme-2;
    padding: 0 23px;
  }

   @media(max-width: 1200px) {
     font-size: 0.875rem;
     letter-spacing: 0.08px;
     line-height: 1.75rem;
     min-height: 1.9375rem;

     i {
       font-size: 1.25rem;
       line-height: inherit;
     }

     &.primary {
       padding: 0 15px;
     }
   }

  &.small {
    font-size: 0.875rem;
    letter-spacing: 0.08px;
    line-height: 1.8125rem;
    min-height: 1.9375rem;
    padding: 0 15px;

    i {
      font-size: 1.25rem;
      line-height: inherit;
    }

     @media(max-width:1200px) {
       font-size: 0.875rem;
       letter-spacing: 0.08px;
       line-height: 1.8125rem;
       min-height: 31px;
       padding: 0 15px;
     }
  }
}
